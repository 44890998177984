import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponsiveService } from '@services/responsive';
import { NgIf, AsyncPipe } from '@angular/common';
import { TextComponent } from '../../../../typography/components/text/text.component';

@Component({
    selector: 'statistic-card-item',
    template: `
    <div class="wrapper">
      <div class="d-flex">
        <ec-text class="me-1">
          <ng-container *ngIf="mobileLabel && (isMobile$ | async); else other">
            {{ mobileLabel }}
          </ng-container>
          <ng-template #other>
            {{ label }}
          </ng-template>
        </ec-text>
        <ng-content select="ec-dropdown"></ng-content>
      </div>
      <ec-text
        color="primary"
        weight="600"
        [size]="size"
        [touchSize]="size === 'lg' ? 'lg' : undefined"
        [adaptSmallDesktop]="adaptSmallDesktop"
        class="mt-1"
      >
        <ng-content></ng-content>
      </ec-text>
    </div>
  `,
    styleUrls: ['./statistic-card-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        TextComponent,
        NgIf,
        AsyncPipe,
    ],
})
export class StatisticCardItemComponent {
  @Input() label!: string;

  @Input() mobileLabel!: string;

  @Input() size: 'lg' | 'xxl' = 'xxl';

  @Input() adaptSmallDesktop: boolean;

  @HostBinding('class') @Input() column = 'col-4';

  isMobile$: Observable<boolean> = this.responsiveService.isTouch$;

  constructor(private responsiveService: ResponsiveService) {}
}
