import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable, take } from 'rxjs';
import { AuthService, NavigationElement } from '@services/auth';
import { ResponsiveService } from '@services/responsive';
import { LandingService } from '../../../../routing/landing/services/landing.service';
import { ButtonComponent } from '../../../buttons/components/button/button.component';
import { TextComponent } from '../../../typography/components/text/text.component';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: '[ec-invest-action-call]',
    templateUrl: './invest-action-call.component.html',
    styleUrls: ['./invest-action-call.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        TextComponent,
        ButtonComponent,
        AsyncPipe,
    ],
})
export class InvestActionCallComponent {
  public isMobile$: Observable<boolean> = this.responsiveService.isMobile$;

  accreditationLink$ = this.landingService.getAccreditationLink$();

  @Input() isAuthorized: boolean | null;

  @Input() accreditationExpired: boolean;

  constructor(
    private readonly authService: AuthService,
    private readonly responsiveService: ResponsiveService,
    private landingService: LandingService,
  ) {
    authService.isAuthorized$.pipe(take(1)).subscribe(isAuthorized => {
      if (isAuthorized) {
        this.landingService.fetchAccreditationLink();
      }
    });
  }

  handleLoginClick(): void {
    this.authService.navigate(NavigationElement.login);
  }

  handleRegisterClick(): void {
    this.authService.navigate(NavigationElement.register);
  }

  goToPandaDoc(link: string | null): void {
    if (link) {
      window.open(link, '_blank');
    }
  }
}
